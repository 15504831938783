import "./About.scss";
import colors from "./color-palettes.json";
import { useEffect, useState } from "react";

// component imports
import Header from "../../components/Header/Header";
import handleResize from "../../helpers/windowResize";
import AboutModal from "../../components/AboutModal/AboutModal";

// animation imports
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { SplitText } from "gsap/all";
import { CustomEase } from "gsap/CustomEase";

const About = () => {
  // set modal state
  const [isModalOpen, setModalOpen] = useState(false);

  // set random color palette
  const [palette, setPalette] = useState(() => {
    const len = colors["color-palette"].length;
    const randomIndex = Math.floor(Math.random() * len);
    return colors["color-palette"][5];
  });

  // open modal function
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  // resize window event listener for mobile responsiveness
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    gsap.set(".square-frame", {
      transformOrigin: "center center",
      x: -141,
      y: -217,
    });
    const loopTime = 16;
    const ballDuration = loopTime / 8;
    const introRotTime = 1.3;
    const introRotDelay = 0;
    const squareDelay = introRotTime + introRotDelay;

    // title split text
    const aboutST = new SplitText(".about-title", {
      type: "chars",
      position: "absolute",
    });

    const learnST = new SplitText(".about-button span", {
      type: "chars",
      position: "relative",
    });

    const constEase = CustomEase.create(
      "custom",
      `M0,0,
    C0.1,0.025,

    0.125,0.225,
    0.25,0.25,
    0.375,0.275,

    0.375,0.475,
    0.5,0.5,
    0.625,0.525,

    0.625,0.725,
    0.75,0.75,
    0.875,0.775,

    0.875,0.975
    ,1,1`
    );

    let aboutTl = gsap.timeline({ delay: 0.5 });

    aboutTl
      .from(
        ".square__large",
        {
          scaleX: 0,
          duration: 2,
          ease: "power4.inOut",
          transformOrigin: "right center",
        },
        0
      )
      .to(
        ".square-frame",
        {
          rotation: 16,
          duration: introRotTime,
          ease: CustomEase.create(
            "custom",
            "M0,0,C0.234,0.528,0.524,0.828,1,1"
          ),
        },
        introRotDelay
      )
      .from(
        ".square__small",
        {
          scaleX: 0,
          duration: 2,
          ease: "power4",
          transformOrigin: "right center",
        },
        1.1
      )
      .set([".ball", ".dark-patch"], { visibility: "visible" }, 1.3)
      .fromTo(
        ".ball",
        {
          x: -206,
        },
        {
          x: 58,
          ease: "ballBounce",
          duration: 2,
        },
        1.3
      );

    let squareTl = gsap.timeline({
      delay: squareDelay + 0.5,
      repeat: -1,
    });

    squareTl.to(".square", {
      duration: loopTime,
      ease: constEase,
      rotation: "+=360",
      transformOrigin: "center center",
    });

    let ballTl = gsap.timeline({
      repeat: -1,
      delay: 4.65,
      repeatDelay: ballDuration,
      defaults: {
        ease: "ballBounce",
        duration: ballDuration,
      },
    });

    ballTl
      .to(".ball", {
        y: -58,
      })
      .to(
        ".ball",
        {
          x: 0,
        },
        loopTime / 4
      )
      .to(
        ".ball",
        {
          y: 0,
        },
        loopTime / 2
      )
      .to(
        ".ball",
        {
          x: 58,
        },
        loopTime * 0.75
      );

    // title animation
    let titleTl = gsap.timeline({
      ease: "power2",
      duration: 2,
      delay: 1.5,
    });

    titleTl
      .from(
        aboutST.chars,
        {
          opacity: 0,
          y: -120,
          stagger: 0.05,
        },
        0
      )
      .from(
        learnST.chars,
        {
          opacity: 0,
          y: 60,
          rotationX: 90,
          stagger: 0.05,
        },
        0
      )
      .to(
        ".about-text",
        {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        },
        0.15
      );
  });

  return (
    <>
      <Header />
      <div
        className="about-container"
        style={{ backgroundColor: palette.background }}
      >
        <div className="about-animation">
          <svg className="svg" viewBox="0 0 716 1008">
            <g className="square-frame">
              <g className="square">
                <rect
                  className="square__large"
                  width="868"
                  height="868"
                  fill={palette.square}
                />
                <rect
                  className="square__small"
                  x="303"
                  y="303"
                  width="262"
                  height="262"
                  fill={palette.squareSmall}
                />
                <circle
                  className="ball"
                  cx="405"
                  cy="463"
                  r="103"
                  fill={palette.ball}
                />
              </g>
            </g>
          </svg>
          <div className="about-text">
            <p>i'm kevin barbour.</p>
            <p>
              i'm a freelance full-stack developer based in Brooklyn, New York.
              I love the process of turning ideas into visually appealing,
              tangible, working applications that positively impact and enrich
              lives.
            </p>
            <p>
              I'm eager to connect with individuals and organizations that share
              a vision for innovative, impactful projects and a care for people.
              If you have an idea you're passionate about bringing to life,
              let's connect.
            </p>
          </div>
          <h1 className="about-title" style={{ color: palette.title }}>
            about
          </h1>
        </div>
      </div>
    </>
  );
};

export default About;
